import React from "react";
import "./contact-us.scss";

var ContactUs = ()=> {

      return (
        <div>
          <div className="sp-listing-bg">
            <div className="container">
              <p className="profile-title">დაგვიკავშირდით</p>
            </div>
          </div>
          <div className="container">
            <div className="row contact-container">
              <div className="col-lg-9 col-12">
                <img  src="../images/contact.png" className="contact-img" />
              </div>
              <div className="col-lg-3 col-12">
                <p className="contact-info">ტელეფონი</p>
                <p className="contact-detail">(+995) 550 50 30 86</p>
                <p className="contact-info">ელ-ფოსტა</p>
                <p className="contact-detail">info@contacti.ge</p>                
                <p className="contact-info">მისამართი</p>
                <p className="contact-detail">ქუჩა, თიბილისი, საქართველო</p>                
              </div>
            </div>
          </div>
          <div className="container contact-form-container">
            <div className="contact-mobile-inner-container">
              <p className="contact-form-title"></p>
              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="form-group">
                    <label for="input-id" id="label__text">სახელი</label>
                    <input type="text" className="form-control" id="input-id" />
                  </div>
                  <div className="form-group">
                    <label for="input-id" id="label__text">ელ.ფოსტა</label>
                    <input type="email" className="form-control" id="input-id"/>
                  </div>
                  <div className="form-group">
                    <label for="input-id" id="label__text">ტელეფონი</label>
                    <input type="number" className="form-control" id="input-id" />
                  </div>
                </div>
                <div className="col-lg-6 col-12 contact-text">
                  <div className="form-group">
                    <label for="input-id" id="label__text">შეტყობინება</label>
                    <textarea className="form-control" id="input-id" defaultValue={""} />
                  </div>
                  <button className="btn btn-primary btn-block contact-send-btn">გაგზავნა</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    export default ContactUs;