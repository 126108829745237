import React, {useState} from 'react';
import BlogTopics from './blog-landing-page-components/blog-topics';
import BlogTabs from './blog-landing-page-components/blog-tabs';

const Blog = () => {
    const [selectedTab, setSelectedTab] = useState(['ყველა კატეგორია']);
    return (
        <div className='blog__content'>
            <BlogTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab}/> 
            <BlogTopics selectedTab={selectedTab}/>
        </div>
  )
}

export default Blog