import React, { useEffect, useState } from "react"
import { useHistory, useLocation, useParams } from 'react-router-dom';
import "./blog-topic-details.scss";
import BlogCard from "../blog-landing-page-components/blog-card";
import BlogComments from "./blog-comments";
import { useCookies } from "react-cookie";
import axios from "axios";
import { API_BASE_URL } from "../../../constants/apiConstants";
import LeaveCommentTextbox from "./blog-comment-textbox";
import moment from "moment/moment";

function BlogTopicsDetails(){

    const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
    const [shareMenuVisible, setShareMenuVisible] = useState(false);
    const [articleDetails, setArticleDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [mustUpdateComments, SetMustUpdateComments] = useState(0)
    let { id } = useParams();

    console.log("333333333333333333333333333333333333333333",mustUpdateComments)

    useEffect(() => {
        async function getArticleById(){
            try{
                const articleById = await axios.get(API_BASE_URL + `/api/blog/ka/article/${id}`);
                console.log(articleById)
                setArticleDetails(articleById.data);
                setIsLoading(false)
            }catch(err) {
                console.log(err.toString()) 
                setIsLoading(false)               
            }           
        }
        getArticleById(); 
    }, [mustUpdateComments])

    function likePost() {
        if (cookies.ContactiAccessToken !== undefined && cookies.ContactiAccessToken !== "undefined"){
        alert("sfdgsf")
        }
    }

    return (
        <div className="blog__topics__container">
            {!isLoading ? <div className="blog__text">
                <div className="article__name">{articleDetails.name}</div> 
                <div className="blog__info__panel">
                    <div style={{display:'flex', alignItems:'center'}}>
                        <div className="items" style={{color:'#3989f4'}}>{articleDetails.Category.name}</div>
                        <div className="line__between" style={{margin:'0 1rem'}}></div>
                        <div style={{color: '#909090', fontSize:'12px'}}>{moment(articleDetails.postDate).format('YYYY-MM-DD')}</div>
                    </div>
                    <div  style={{position:'relative', display:'flex', flexDirection:'column', alignItems:'center'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div className="items" style={{display:'flex', alignItems:'center'}}><i role="button" className="bi bi-hand-thumbs-up" onClick={() => {likePost()}}></i><div className="line__between" style={{margin:'0 1rem'}}></div><i role="button" className="bi bi-hand-thumbs-down"></i></div>
                            <div onClick={() => setShareMenuVisible(prev => !prev)} className="items" style={{color:'#3989f4', marginLeft:'2rem'}}><i role="button" className="bi bi-share-fill no-italic">&nbsp;გაზიარება</i></div>
                        </div>
                        <div className={shareMenuVisible ? "share__menu" : "d-none"}>
                            <div className="ml-3"><i className="bi bi-facebook mr-2"></i>გაზიარება Facebook-ზე</div>
                            <div className="share__links__linebetween"></div>
                            <div>
                            <svg style={{marginLeft:'1rem', marginRight:'5px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-messenger" viewBox="0 0 16 16">
                            <path d="M0 7.76C0 3.301 3.493 0 8 0s8 3.301 8 7.76-3.493 7.76-8 7.76c-.81 0-1.586-.107-2.316-.307a.639.639 0 0 0-.427.03l-1.588.702a.64.64 0 0 1-.898-.566l-.044-1.423a.639.639 0 0 0-.215-.456C.956 12.108 0 10.092 0 7.76zm5.546-1.459-2.35 3.728c-.225.358.214.761.551.506l2.525-1.916a.48.48 0 0 1 .578-.002l1.869 1.402a1.2 1.2 0 0 0 1.735-.32l2.35-3.728c.226-.358-.214-.761-.551-.506L9.728 7.381a.48.48 0 0 1-.578.002L7.281 5.98a1.2 1.2 0 0 0-1.735.32z"/>
                            </svg>გაზიარება Messenger-ზე</div>                            
                            <div className="share__links__linebetween"></div>
                            <div className="ml-3"><i className="bi bi-linkedin mr-2"></i>გაზიარება LinkedIn-ზე</div>
                        </div>
                    </div>
                </div>
                <p className="article__text">                
                    {articleDetails.content}
                </p> 
                <LeaveCommentTextbox articleId={id} SetMustUpdateComments={SetMustUpdateComments}/>               
            </div> : ""}
            <div className="blog__recomended" style={{display:'flex', flexDirection:'column', gap:'1rem'}}>
                <div style={{fontSize:'16px', fontWeight:'bold'}}>რეკომენდებული თქვენთვის</div>
                <BlogCard 
                    key={1} 
                    imageUrl={"../images/bottom-banner.jpg"} 
                    name={"სკამის რემონტი"} 
                    category={"სანტექნიკა"} 
                    summary={"დიდი გამოწვევაა მომსახურების სფეროში."} 
                    postDate={"27 წუთის წინ"} />
                <BlogCard 
                    key={2} 
                    imageUrl={"../images/register_bg.png"} 
                    name={"ბუხრის აშენება"} 
                    category={"სანტექნიკა"} 
                    summary={"ჩვენი პლატფორმის მიზანია ნდობასთან და ხარისხთან დაკავშირებული პრობლემების გადაჭრა, რომელიც დიდი გამოწვევაა მომსახურების სფეროში."} 
                    postDate={"27 წუთის წინ"} />
                <BlogCard 
                    key={3} 
                    imageUrl={"../images/bottom-banner.jpg"} 
                    name={"გადუდებელი სამუშაოები"} 
                    category={"გადუდებელი სამუშაოები"} 
                    summary={"contacti.ge არის პლატფორმა, რომელიც გაძლევთ საშუალებას სწრაფად და მარტივად მოიძიოთ საქმის პროფესიონალი!"} 
                    postDate={"27 წუთის წინ"} />
            </div>
            <BlogComments  mustUpdateComments={mustUpdateComments} articleId={id} SetMustUpdateComments={SetMustUpdateComments} /> 

        </div>
    )

}

export default BlogTopicsDetails