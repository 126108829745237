// import React, {useState} from 'react'

function CategorySearchBox() {
    return (        
        <div className="input-group">
            <div className="input-group-prepend">
            <div className="input-group-text"><i className="bi bi-search"></i></div>
            </div>
            <input type="text" className="form-control" id="inlineFormInputGroup" placeholder="ძებნა..."/>
        </div>   
    )
    
}

export default CategorySearchBox