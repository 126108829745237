import axios from "axios";
import { API_BASE_URL } from "../../../constants/apiConstants";
import { useCookies } from 'react-cookie';


function DeleteCommentModal( { setDeleteCommentVisible, commentId } ) {
  const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);


  async function deleteComment() {
    try{
      const authAxios = axios.create({
        headers: {
          Authorization: `Bearer ${cookies.ContactiAccessToken}`
      }
      })
      await authAxios.post(API_BASE_URL + `/api/comment/deletecomment/${commentId}`);
    }catch(err){
      console.log(err.toString())
    }    
  }

  return (    
      <div className="modal fade" id="removeJobModal" tabIndex={-1} aria-labelledby="removeJobModal" aria-hidden="true">
        <div className="modal-dialog modal-sm" style={{marginTop:'100px'}}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">კომენტარის წაშლა</h5>
            </div>
            <div className="modal-body">
              <p className="default-font">ნამდვილად გსურთ კომენტარის წაშლა?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary default-font" data-dismiss="modal" onClick={() => {setDeleteCommentVisible(false)}}>გაუქმება</button>
              <button type="button" className="btn btn-danger default-font" data-dismiss="modal" onClick={() => {deleteComment(); setDeleteCommentVisible(false)}}>წაშლა</button>
            </div>
          </div>
        </div>
      </div>  
)        
}
 
export default DeleteCommentModal