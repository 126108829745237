import React, { useEffect, useState } from "react";
import BlogCard from "./blog-card";
import axios from "axios";
import { API_BASE_URL } from "../../../constants/apiConstants";
import { Link, useHistory } from 'react-router-dom';


const blogCategoriesData = [
    {
        id: 1,
        image: "../images/register_bg.png",
        title: "როგორ გავარემონტოთ საკამი",
        category: "გადაუდებელი სამუშაოები",
        description: "ჩვენი პლატფორმის მიზანია ნდობასთან და ხარისხთან დაკავშირებული პრობლემების გადაჭრა, რომელიც დიდი გამოწვევაა მომსახურების სფეროში.",
        publishDate: "27 წუთს წინ"
    },
    {
        id: 2,
        image: "../images/bottom-banner.jpg",
        title: "როგორ გავარემონტოთ საკამი",
        category: "სანტექნიკოსი",
        description: "ჩვენი პლატფორმის მიზანია ნდობასთან და ხარისხთან დაკავშირებული პრობლემების გადაჭრა, რომელიც დიდი გამოწვევაა მომსახურების სფეროში.",
        publishDate: "27 წუთს წინ"
    },
    {
        id: 3,
        image: "../images/register_bg.png",
        title: "როგორ გავარემონტოთ საკამი",
        category: "სანტექნიკოსი",
        description: "ჩვენი პლატფორმის მიზანია ნდობასთან და ხარისხთან დაკავშირებული პრობლემების გადაჭრა, რომელიც დიდი გამოწვევაა მომსახურების სფეროში.",
        publishDate: "27 წუთს წინ"
    },
    {
        id: 4,
        image: "../images/bottom-banner.jpg",
        title: "როგორ გავარემონტოთ საკამი",
        category: "უსაფრთხოების სისტემები",
        description: "ჩვენი პლატფორმის მიზანია ნდობასთან და ხარისხთან დაკავშირებული პრობლემების გადაჭრა, რომელიც დიდი გამოწვევაა მომსახურების სფეროში.",
        publishDate: "27 წუთს წინ"
    },
    {
        id: 5,
        image: "../images/register_bg.png",
        title: "როგორ გავარემონტოთ საკამი",
        category: "უსაფრთხოების სისტემები",
        description: "ჩვენი პლატფორმის მიზანია ნდობასთან და ხარისხთან დაკავშირებული პრობლემების გადაჭრა, რომელიც დიდი გამოწვევაა მომსახურების სფეროში.",
        publishDate: "27 წუთს წინ"
    },
    {
        id: 6,
        image: "../images/bottom-banner.jpg",
        title: "როგორ გავარემონტოთ საკამი",
        category: "არქიტექტურა და დიზაინი",
        description: "ჩვენი პლატფორმის მიზანია ნდობასთან და ხარისხთან დაკავშირებული პრობლემების გადაჭრა, რომელიც დიდი გამოწვევაა მომსახურების სფეროში.",
        publishDate: "27 წუთს წინ"
    }
]

function BlogTopics( {selectedTab} ) {

    const [blogCategoryArticles, setBlogCategoryArticles] = useState([]);
    const history = useHistory();

    
    useEffect(() => {
        async function getAllBlogTopics(){
            const response = await axios.get(API_BASE_URL + `api/blog/ka/articles`);
            console.log("BLOG TOPICS",response)
            setBlogCategoryArticles(response.data)
        }
        getAllBlogTopics()
    }, [])

    const readFullArticle = (articleId) => {
        history.push(`/blog-topic-details/${articleId}`);
      };

    function returnFilteredArticles() {
        let tempArray = []
        let blogTopicsMobile =[];

        for (let i = 0; i < selectedTab.length; i++){
            blogTopicsMobile = blogCategoryArticles.filter(blogCategory => blogCategory.Category.name === selectedTab[i])
            tempArray.push(...blogTopicsMobile);
        }
      return tempArray
    }

    return(
        <>
            <div className='blog__topics'>
            {
                blogCategoryArticles.length !== 0 && selectedTab[0] === "ყველა კატეგორია" ? blogCategoryArticles.map((item, index) => {
                    return (
                        <div key={index} onClick={() => readFullArticle(item.id)}> 
                            <BlogCard 
                                id={item.id} 
                                imageUrl={API_BASE_URL + item.imageUrl} 
                                name={item.name} 
                                category={item.Category.name} 
                                summary={item.summary} 
                                postDate={item.postDate}
                            />
                        </div>
                    )}) : returnFilteredArticles().length !==0 ? returnFilteredArticles().map((filteredCategory, index) => {
                            return (
                                <div key={index} onClick={() => readFullArticle(filteredCategory.id)}>
                                <BlogCard 
                                    id={filteredCategory.id} 
                                    imageUrl={API_BASE_URL + filteredCategory.imageUrl} 
                                    name={filteredCategory.name} 
                                    category={filteredCategory.Category.name} 
                                    summary={filteredCategory.summary} 
                                    postDate={filteredCategory.postDate}
                                />
                                </div>
                            )}) : <div className="no-article-message">აღნიშნულ კატეგორიაში სტატიები ვერ მოიძებნა</div>
            }
            </div>
        </>
    )
}

export default BlogTopics