import React, {useEffect, useState} from "react"
import './blog-comments.scss'
import LeaveCommentTextbox from "./blog-comment-textbox";
import DeleteCommentModal from "./blog-delete-comment";
import { useCookies } from "react-cookie";
import axios from "axios";
import { API_BASE_URL } from "../../../constants/apiConstants";
import moment from "moment/moment";

    function TraverseReplies({ id, userName, content, Children, UserEmail, createdTime, UserType, articleId, SetMustUpdateComments }){ 
        const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);    
        const [showRepliesOnComment, setShowRepliesOnComment] = useState(false);
        const [commentAreaVisible, setCommentAreaVisible] = useState(false);
        const [deleteCommentVisible, setDeleteCommentVisible] = useState(false);
        const [commentId, setCommentId] = useState(0);

        function checkAuthorType(user){
            if (user === 0)
                return "მომხმარებელი"
            return "ხელოსანი"
        }

            return (
                <div className="blog__comments">
                    <div className="comment__author">{userName}&nbsp;<span>{moment(createdTime).format('YYYY-MM-DD')}</span></div> 
                    <div className="author__status">{checkAuthorType(UserType)}</div>
                    <div className="blog__comment">{content}</div>
                    {deleteCommentVisible ? <DeleteCommentModal setDeleteCommentVisible={setDeleteCommentVisible} commentId={id}/> : ""}
                    {commentAreaVisible ? <LeaveCommentTextbox commentId={commentId} articleId={articleId} replyOnComment={true} SetMustUpdateComments={SetMustUpdateComments}/> : ""} 
                    <div className="d-flex align-items-center">
                        <i className="bi bi-hand-thumbs-up"></i>
                        <div className="line__between mr-3 ml-3"></div>
                        <i className="bi bi-hand-thumbs-down"></i>
                        <span role="button" className="comment-reply text-primary ml-5" onClick={() => {setCommentAreaVisible(prev => !prev); setCommentId(id)}}>კომენტარის დატოვება</span>
                        <span role="button" className={UserEmail === cookies.ContactiUsername ? "comment-reply text-danger ml-5" : "d-none"} onClick={() => {setDeleteCommentVisible(true); setCommentId(id)}}>წაშლა</span>
                    </div>
                    <div>
                            {Children && Children.length > 0 ? 
                                <div onClick={() => setShowRepliesOnComment(prev => !prev)}>
                                    <span className="reply__button"><img className={showRepliesOnComment ? "expand__replies__arrow__open" : "expand__replies__arrow__close"} src="../images/gallery/down-arrow.png" />{Children.length} კომენტარი</span>
                                </div> : ""}
                        </div>                                  
    
                    {showRepliesOnComment ? Children?.map((item) => ( 
                        <>
                        <TraverseReplies key={(item.id).toString()} {...item} />  </>                
                    )) : ""}
    
                </div>
            )
        }



function BlogComments ({ articleId, mustUpdateComments, SetMustUpdateComments }){    
    const [allCommentsForArticle, setAllCommentsForArticle] = useState([])

    useEffect(() => {

        async function getAllCommentsForArticle(){
            try{
                const response = await axios.get(API_BASE_URL + `/api/blog/ka/comments/${articleId}`);

                setAllCommentsForArticle(response.data)
                // console.log("COMMENTSSSSSSSSSSSSSSSSss",response)    
            }catch(err){
                console.log(err.toString())
            }
        }

        getAllCommentsForArticle()
    }, [mustUpdateComments])
    
    return (
        <div className="blog__topics"> 
            {allCommentsForArticle.map((item) => (
                <TraverseReplies key={(item.id).toString()} {...item} articleId={articleId} SetMustUpdateComments={SetMustUpdateComments}/>
            ))}        
        </div>
    )
}
    
export default BlogComments