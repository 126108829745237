import "./header.scss";

import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Cookies from "js-cookie";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import MenuDropdown from "./dropdown";
import Notifications from "../notifications";
import { API_BASE_URL } from "../../constants/apiConstants";
import axios from "axios";

function Header(props) {
  const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [hasNotification, setHasNotification] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [profile, setProfile] = useState([]);
  const [profileImage, setProfileImage] = useState("");
  const node = React.useRef();

  useEffect(() => {
    const apiUrl = API_BASE_URL + "/api/user/profile";

    const authAxios = axios.create({
      withCredentials: false,
      baseURL: apiUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`,
      },
    });

    const fetchData = async () => {
      try {
        const result = await authAxios.post(`${apiUrl}`);
        setProfile(result.data);
      } catch (err) {
        console.log("Request faied", err);
      }
    };

    fetchData();
  }, []);

  const isLoggedIn =
    cookies.ContactiAccessToken !== undefined &&
    cookies.ContactiAccessToken !== "undefined";

  const redirectToLogin = () => {
    window.location.href = "/login";
    props.updateTitle("Login");
  };

  const userType = sessionStorage.getItem("userType");
  const isCustomer = userType === "customer" ? true : false;

  const redirectToHome = () => {
    Cookies.remove("ContactiAccessToken", { path: "/" });
    Cookies.remove("ContactiUsername", { path: "/" });
    console.log("gets here");
    window.location.href = "/";
    // props.updateTitle("Home");
  };

  useEffect(() => {
    if (isLoggedIn) {
      checkNotification();
    } else {
    }
  }, []);

  const checkNotificationUrl = `${API_BASE_URL}/api/cs/notifications/has-unread`;

  const h_axios = axios.create({
    withCredentials: false,
    baseURL: checkNotificationUrl,
    headers: {
      Authorization: `Bearer ${cookies.ContactiAccessToken}`,
    },
  });

  const checkNotification = async () => {
    try {
      const resp = await h_axios.get(checkNotificationUrl);
      const resppp = resp;
      setHasNotification(resp);
      console.log("nott:" + resppp);
    } catch (err) {
      console.error(err);
    }
  };

  const gotoJobs = () => {
    isCustomer
      ? (window.location.href = "/customer/customer-profile")
      : (window.location.href = "/jobs");
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const StyledMenu = styled.nav`
    display: flex;
    z-index: 4;
    flex-direction: column;
    justify-content: start;
    background: rgb(31, 31, 31);
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
    height: 100vh;
    text-align: left;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;
    padding-top: 100px;

    @media (max-width: 576px) {
      width: 100%;
    }

    a {
      font-size: 12px;
      text-transform: uppercase;
      padding: 25px 20px;
      color: #dedede;
      border-bottom: 2px solid #383838;
      text-decoration: none;
      transition: color 0.3s linear;

      @media (max-width: 576px) {
        font-size: 16px;
        text-align: left;
      }

      &:hover {
        color: #343078;
      }
    }
  `;


const renderMyProfileLink = () =>{
  if(isCustomer){
    return(<a href="/customer/customer-profile" className="fcaps">ჩემი პროფილი</a>)
  }
  return(<a href="/profile" className="fcaps">ჩემი პროფილი</a>)
}


  const Menu = ({ open }) => {



    return (
      <StyledMenu open={open}>
      {isLoggedIn ? renderMyProfileLink() : ""}
      <a href="/post-a-job" className="fcaps">მომსახურების მოთხოვნა</a>
      <a href="/aboutus" className="fcaps">რას ვაკეთებთ</a>
      {!isLoggedIn ? <a href="/register" className="fcaps">დარეგისტრირდი ხელოსნად</a> : ""}
      <a href="/blog" className="fcaps">ბლოგი</a>
      <a href="/contact-us" className="fcaps">კონტაქტი</a>
    </StyledMenu>
    );
  };

  const StyledBurger = styled.button`
    position: absolute;
    top: 29px;
    right: ${({ open }) => (open ? "10px" : "")};
    left: ${({ open }) => (open ? "" : "0")};
    margin-left: ${({ open }) => (open ? "35px" : "15px")};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;

    height: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 30;

    &:focus {
      outline: none;
    }

    div {
      width: ${({ open }) => (open ? "22px" : "2rem")};
      height: 2px;
      background: ${({ open }) => (open ? "#fff" : "#212529")};
      border-radius: 10px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;

      :first-child {
        transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
      }

      :nth-child(2) {
        opacity: ${({ open }) => (open ? "0" : "1")};
        transform: ${({ open }) =>
          open ? "translateX(20px)" : "translateX(0)"};
      }

      :nth-child(3) {
        transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
      }
    }
  `;

  const Burger = ({ open, setOpen }) => {
    return (
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
    );
  };

  const useOnClickOutside = (ref, handler) => {
    React.useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
      };
    }, [ref, handler]);
  };


  const renderBellIcon = () => {
    if (isLoggedIn) {
      return (
        <img
          alt=""
          src="../../images/bell-dot.png"
          width="16px"
          height="16px"
        />
      );
    }
    return (
      <img alt="" src="../../images/bell.png" width="16px" height="16px" />
    );
  };

  // ///////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      {showNotifications && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            position: "absolute",
            zIndex: "1",
          }}
          onClick={() => toggleNotifications()}
        ></div>
      )}

      <div className="header-container">
        <div className="row header-wrapper">
          {/* burgeri */}
          {isMobile ? (
            <div ref={node}>
              {open ? (
                <div className="menu-head">
                  <div className="menu-text">Contacti</div>
                  <Burger open={open} setOpen={setOpen} />
                </div>
              ) : (
                <Burger open={open} setOpen={setOpen} />
              )}
              <Menu open={open} setOpen={setOpen} />
            </div>
          ) : (
            ""
          )}

          <div id="myNav" className="overlay">
            <div className="overlay-content">
              <a href="#">Home</a>
              <hr className="overlay-menu" />
              <a href="/register">Apply as a service provider</a>
              <hr className="overlay-menu" />
              <a href="#">Service providers</a>
              <hr className="overlay-menu" />
              <a href="#">Categories</a>
              <hr className="overlay-menu" />
              <a href="#">Post a job</a>
            </div>
          </div>

          {/* <div className="col-xl-8 col-lg-6  col-md-6 navi-menu"> */}
          <div style={{width:'100%', display:'flex'}}>
          <div className=" navi-menu">
            <ul className="nav customnav">
              <li className="nav-item main-logo">
                <a href="/">
                  <img src="../../images/logo2.png" style={{height:'44px'}}/>
                </a>
              </li>

              {/* {!isLoggedIn ? (
                <>
                <li className="nav-item">
                  <a className="nav-link fcaps" href="/register">
                    იმუშავე&nbsp;ჩვენთან
                  </a>
                </li>
               
              </>
              ) : (
                ""
              )} */}

              <li className="nav-item" >
                  <a className="nav-link fcaps" style={{width:'280px'}} href="/register">
                    დარეგისტრირდი&nbsp;ხელოსნად
                  </a>
                </li>
            <li className="nav-item">
                <a className="nav-link fcaps" href="/aboutus">
                  რას&nbsp;ვაკეთებთ
                </a>
              </li>
              <li className="nav-item" >
                  <a className="nav-link fcaps" href="/how-it-works">
                    როგორ&nbsp;მუშაობს?
                  </a>
                </li>
              <li className="nav-item" >
                  <a className="nav-link fcaps" href="/blog">
                    ბლოგი
                  </a>
                </li>
            </ul>
          </div>

          {/* ///////////////////////////////////// */}

{/* 
          <div
            className={`col-xl-4  col-lg-6 col-md-6 col-sm-2 login-btn-wrapper ${
              isLoggedIn ? "is-logged" : ""
            }`}
          > */}

          <div
            className={`login-btn-wrapper ${
              isLoggedIn ? "is-logged" : ""
            }`}
          >
            {cookies.ContactiAccessToken &&
            cookies.ContactiAccessToken !== "undefined" ? (
              <>
                <a
                  className="col-xl-1 col-lg-8 col-md-6 col-sm-6 logo-container"
                  href="/"
                >
                  <img
                    alt=""
                    className="logo-mobile"
                    src="../../images/logo2.png" style={{height:'44px'}}
                  />
                </a>
                <a
                  className="bell-icon"
                  onClick={() => toggleNotifications()}
                  style={{ position: "relative", cursor: "pointer" }}
                >
                  {renderBellIcon()}
                  {showNotifications && <Notifications isCustomer={isCustomer} />}
                </a>

                <a
                  type="button"
                  className="btn btn-success jobs-button"
                  onClick={() => gotoJobs()}
                >
                  საქმეები
                </a>
                {isMobile ? (
                  isCustomer ? (
                    <a
                      className="header-user-login-wrapper"
                      href="/customer/customer-profile"
                    >

                  <img
                    src={
                        profileImage
                        ? profileImage.AvatarUrl
                        : profile.AvatarUrl
                        ? `http://staging-cms.contacti.ge${profile.AvatarUrl}`
                        : "../../images/profile-picture.jpg"
                    }
                    className="header-user-login"
                    />


                    </a>
                  ) : (
                    <a className="header-user-login-wrapper" href="/profile">
                     <img
                    src={
                        profileImage
                        ? profileImage.AvatarUrl
                        : profile.AvatarUrl
                        ? `http://staging-cms.contacti.ge${profile.AvatarUrl}`
                        : "../../images/profile-picture.jpg"
                    }
                    className="header-user-login"
                    />
                    </a>
                  )
                ) : isCustomer ? (
                  <a
                    className="btn btn-primary header-user-login-wrapper"
                    href="/customer/customer-profile"
                  >
                    <img
                    src={
                        profileImage
                        ? profileImage.AvatarUrl
                        : profile.AvatarUrl
                        ? `http://staging-cms.contacti.ge${profile.AvatarUrl}`
                        : "../../images/profile-picture.jpg"
                    }
                    className="header-user-login"
                    />
                    პროფილი
                  </a>
                ) : (
                  <a
                    className="btn btn-primary header-user-login-wrapper"
                    href="/profile"
                  >
                    <img
                      className="header-user-login"
                      src={
                        profile.AvatarUrl
                          ? `http://staging-cms.contacti.ge${profile.AvatarUrl}`
                          : "../../images/profile-picture.jpg"
                      }
                    />
                    პროფილი
                  </a>
                )}
                <button
                  type="button"
                  className="btn btn-primary login-button"
                  onClick={() => redirectToHome()}
                >
                  <img
                    src="../../images/login-icon.png"
                    width="17px"
                    height="17px"
                  />{" "}
                  გამოსვლა
                </button>
              </>
            ) : isMobile ? (
              <>
                <a
                  className="col-xl-1 col-lg-8 col-md-6 col-sm-6 col-12 logo-container"
                  href="/"
                >
                  <img className="logo-mobile" src="../../images/logo2.png" style={{height:'44px'}} />
                </a>
                <MenuDropdown />
              </>
            ) : (
              <button
                type="button"
                className="btn btn-primary login-button"
                onClick={() => redirectToLogin()}
              >
                <div className="login-button-capt">
                  <div>
                    <img
                      src="../../images/login-icon.png"
                      width="17px"
                      height="17px"
                    />
                  </div>
                  <div className="fcaps login-button-lbl">ავტორიზაცია</div>
                </div>
              </button>
            )}
          </div>
          </div>
        </div>
      </div>
    </>

    // ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // return (
    //   <div className="header-container">
    //   <div className="row header-wrapper">
    //     {isMobile ? (
    //       <div ref={node}>
    //         {open ? (
    //           <div className="menu-head">
    //             <div className="menu-text">Menu</div>
    //             <Burger open={open} setOpen={setOpen} />
    //           </div>
    //         ) : (
    //           <Burger open={open} setOpen={setOpen} />
    //         )}
    //         <Menu open={open} setOpen={setOpen} />
    //       </div>
    //     ) : (
    //       ""
    //     )}

    //     <div id="myNav" className="overlay">
    //       <div className="overlay-content">
    //         <a href="#">Home</a>
    //         <hr className="overlay-menu" />
    //         <a href="/register">Apply as a service provider</a>
    //         <hr className="overlay-menu" />
    //         <a href="#">Service providers</a>
    //         <hr className="overlay-menu" />
    //         <a href="#">Categories</a>
    //         <hr className="overlay-menu" />
    //         <a href="#">Post a job</a>
    //       </div>
    //     </div>

    //     <div className="col-xl-8 navi-menu">
    //       <ul className="nav customnav">
    //         <li className="nav-item main-logo">
    //           <a href="/">
    //             <img src="../../images/logo.png" />
    //           </a>
    //         </li>

    //         {!isLoggedIn ? (
    //           <li className="nav-item">
    //             <a className="nav-link fcaps" href="/register">
    //               სერვისის შეთავაზება
    //             </a>
    //           </li>
    //         ) : (
    //           ""
    //         )}
    //         <li className="nav-item">
    //           <a className="nav-link fcaps" href="#">
    //             სერვისის პროვაიდერები
    //           </a>
    //         </li>
    //         <li className="nav-item">
    //           <a className="nav-link fcaps" href="#">
    //             კატეგორიები
    //           </a>
    //         </li>
    //         <li className="nav-item">
    //           <a className="nav-link fcaps" href="/post-a-job">
    //             მომსახურების მოთხოვნა
    //           </a>
    //         </li>
    //       </ul>
    //     </div>

    //     <div
    //       className={`col-xl-4 col-lg-4 col-md-4 col-sm-2 login-btn-wrapper ${
    //         isLoggedIn ? "is-logged" : ""
    //       }`}
    //     >
    //       {cookies.ContactiAccessToken &&
    //       cookies.ContactiAccessToken !== "undefined" ? (
    //         <>
    //           <a
    //             className="col-xl-1 col-lg-8 col-md-6 col-sm-6 col-5 logo-container"
    //             href="/"
    //           >
    //             <img alt="" className="logo-mobile" src="../../images/logo.png" />
    //           </a>
    //           <a className="bell-icon">
    //             <img
    //               alt=""
    //               src="../../images/bell-dot.png"
    //               width="16px"
    //               height="16px"
    //             />
    //           </a>
    //           <a
    //             type="button"
    //             className="btn btn-success jobs-button"
    //             onClick={() => gotoJobs()}
    //           >
    //             Jobs
    //           </a>
    //           {isMobile ? (
    //             isCustomer ? (
    //               <a
    //                 className="header-user-login-wrapper"
    //                 href="/customer/customer-profile"
    //               >
    //                 <img
    //                   className="header-user-login"
    //                   src="../../images/profile-picture.jpg"
    //                 />
    //               </a>
    //             ) : (
    //               <a className="header-user-login-wrapper" href="/profile">
    //                 <img
    //                   className="header-user-login"
    //                   src="images/profile-picture.jpg"
    //                 />
    //               </a>
    //             )
    //           ) : isCustomer ? (
    //             <a
    //               className="btn btn-primary header-user-login-wrapper"
    //               href="/customer/customer-profile"
    //             >
    //               <img
    //                 className="header-user-login"
    //                 src="../../images/profile-picture.jpg"
    //               />
    //               Profile
    //             </a>
    //           ) : (
    //             <a
    //               className="btn btn-primary header-user-login-wrapper"
    //               href="/profile"
    //             >
    //               <img
    //                 className="header-user-login"
    //                 src="../../images/profile-picture.jpg"
    //               />
    //               Profile
    //             </a>
    //           )}
    //           <button
    //             type="button"
    //             className="btn btn-primary login-button"
    //             onClick={() => redirectToHome()}
    //           >
    //             <img
    //               src="../../images/login-icon.png"
    //               width="17px"
    //               height="17px"
    //             />{" "}
    //             Logout
    //           </button>
    //         </>
    //       ) : isMobile ? (
    //         <>
    //           <a
    //             className="col-xl-1 col-lg-8 col-md-6 col-sm-6 col-5 logo-container"
    //             href="/"
    //           >
    //             <img className="logo-mobile" src="../../images/logo.png" />
    //           </a>
    //           <MenuDropdown />
    //         </>
    //       ) : (
    //         <button
    //           type="button"
    //           className="btn btn-primary login-button"
    //           onClick={() => redirectToLogin()}
    //         >
    //           <div className="login-button-capt">
    //             <div>
    //               <img
    //                 src="../../images/login-icon.png"
    //                 width="17px"
    //                 height="17px"
    //               />
    //             </div>
    //             <div className="fcaps login-button-lbl">ავტორიზაცია</div>
    //           </div>
    //         </button>
    //       )}
    //     </div>
    //   </div></div>
  );
}

export default Header;
