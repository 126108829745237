import React from 'react'
import './howitworks.scss'

const HowItWorks = () => {
  return (
    <>
      <div className='howitworks__container'>
        <div className='howitworks__title'>          
          <h3>როგორ მუშაობს პლატფორმა?</h3>
          <p>მიჰყევით ქვემოთ მოცემულ 4 მარტივ ნაბიჯს</p>
          <a href='/post-a-job' className='btn btn-primary fcaps' type='button'>იპოვე ხელოსანი</a>          
        </div>
        <div className='howitworks__diagram'>
          <img className='grid__block' src="images/flow-step1.png"/> 
          <i className="bi bi-arrow-right grid__block" style={{color:'#408df4', fontSize:'30px'}}></i>
          <img className='grid__block' src="images/flow-step2.png"/>
          <div className='grid__block'></div> 
          <div className='grid__block'></div> 
          <i className="bi bi-arrow-down grid__block" style={{color:'#408df4', fontSize:'30px'}}></i>
          <img className='grid__block' src="images/flow-step3.png"/>
          <i className="bi bi-arrow-left grid__block" style={{color:'#408df4', fontSize:'30px'}}></i>
          <img className='grid__block' src="images/flow-step4.png"/>  
        </div>
      </div>
    </>
  )
}

export default HowItWorks