import React from 'react'
import "./blog-card.scss"


const BlogCard = (props) => {
  return (
    <div className='card__container'>
        <img src={props.imageUrl}/>
        <div className='card__body'>
            <h4>{props.name}</h4>
            <h5>{props.category}</h5> 
            <div className='card__line'></div>
            <p>{props.summary}</p>
            <div className='publish__date'>{props.postDate}</div> 
        </div>              
    </div>
  )
}

export default BlogCard