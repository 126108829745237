import axios from "axios";
import "./blog-comment-textbox.scss"
import { API_BASE_URL } from "../../../constants/apiConstants";
import { useCookies } from 'react-cookie';
import { useState } from "react";

function LeaveCommentTextbox( {articleId, commentId, replyOnComment, SetMustUpdateComments} ) {
    const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
    const [userComment, setUserComment] = useState('');

    async function addComment(){

        const addCommentUrl = API_BASE_URL + `/api/blog/ka/postcomment`;
        const payLoad = {
            "content": `${userComment}`,
            "entityId": articleId,
            "parentId": replyOnComment === true ? commentId : null
        }
        try{
            const authAxios = axios.create({
                baseURL: addCommentUrl,
                headers: {
                    Authorization: `Bearer ${cookies.ContactiAccessToken}`
                }
            })
            await authAxios.post(`${addCommentUrl}`, payLoad);
            setUserComment('');
            SetMustUpdateComments(Math.floor((Math.random() * 100000000000) + 1));
        }catch(err){
            console.log(err.toString())
        }  
    }

    function redirectToRegistration() {
        window.location.href = "/login";
    };

    return (
    <div className="comment__textbox__container d-flex flex-column">
        <textarea value={userComment} onChange={(e) => setUserComment(e.target.value)} className="blog__leave__comment"></textarea>
        <button disabled={!userComment.length} onClick={() => {cookies.ContactiAccessToken ? addComment() : redirectToRegistration()}} className={userComment.length ? "leave__comment__button" : "leave__comment__button_disabled"}>გაგზავნა</button>
    </div>
    )
}

export default LeaveCommentTextbox 